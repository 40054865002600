const tt = {
  CreativeTasks: {
    Title: "Иҗади биремнәр",
    UpdateTaskTeacher: "Саклау",
    SubmitTaskTeacher: "Нәтиҗәләрне җибәрү",
    UpdateTaskStudent: "Каралама итеп саклау",
    SubmitTaskStudent: "Саклау һәм тикшерүгә җибәрү",
    AnswerHeaderStatistics:
      "Сораулар: {{questions}} | Символлар: {{symbols}} | Сүзләр: {{words}} | Җөмләләр: {{sentenses}}",
    QuestionsAndComments: "Хаталар һәм комментарийлар:",
    HoverForDisplay: "Күрсәтү өчен курсорны күчерегез:",
    CheckingHistory: "Тикшерү тарихы",
    CurrentVersion: "Хәзерге версия",
    TeacherComment: "Укытучының эшкә карата комментарие:",
    CommentPlaceholder: "Сез монда эш турында аңлатма калдыра аласыз",
    SaveCorrection: "Саклау",
    CancelCorrection: "Юкка чыгару",
    Grammar: "Грамматика",
    Lexical: "Лексика",
    Comment: "Комментарий",
    ErrorRangeFilled:
      "Билгеләнгән диапазонда хаталарның барлык төрләре кулланылган",
    SaveError: "Саклау",
    GrammarErrorTitle: "Грамматика",
    ResultTitle: "Иҗади бирем үтәлеш нәтиҗәләре",
    Student: "Студент",
    Teacher: "Укытучы",
    CurrentVersionTime: "Хәзерге версия: {{time}}",
    TaskText: "Бирем",
    HasErrors: "Хаталар бар",
    WithoutErrors: "Хатасыз башкарылган",
    UpdateSavedTeacher: "Үзгәрешләрегез сакланган",
    UpdateSavedStudent: "Караламасакланган",
    UpdateSubmitedTeacher: "Тикшерү нәтиҗәләре укучыга җибәрелгән",
    UpdateSubmitedStudent: "Төзәтмәләрегез укытучыга җибәрелгән",
    Checking: "Тикшерүдә",
    Done: "Үтәлде",
    FixStudent: "Төзәтү",
    FixTeacher: "Хаталар бар",
    CheckingTime: "тикшерү • {{time}}",
    DoneTime: "җибәрелгән • {{time}}",
    TeacherName: "Укытучы: {{name}}",
    StudentName: "Студент: {{name}}",
    AllStatuses: "Барлык статустар",
    Checked: "Тикшерелгән",
    NewCheck: "Тикшерүдә",
    Processing: "Эшкәртелә",
    StudentSearch: "Студентны эзләү",
    TeacherSearch: "Укытучыны эзләү",
    EmptyList: "Әлегә яраклы биремнәр юк",
  },
};

export default tt;
