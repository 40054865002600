const tt = {
  Groups: {
    title: "Группалар",
  },
  GroupList: {
    group: "Төркем",
    dates: "Шөгыльләр вакыты",
    report: "Шөгыльләр буенча хисаплар",
    statistics: "Платформа статистикасы",
    progress: "Платформа прогрессы",
    lessonsCount: "Үткәрелгән дәресләр",
    total: "Барлык ак. сәг.",
    finished: "Үткәрелгән ак. сәг.",
    left: "Калган ак. сәг.",
    period: "Вакыт аралыгы",
    teachers: "Укытучылар",
    attendance: "Катнашу",
  },
  ModalGroupReport: {
    Download: "Йөкләү",
    Teacher: "Укытучы",
    Course: "Курс",
    Schedule: "Расписание",
    Hours: "ак. сәг.",
    TotalHours: "Барлык ак. сәг.",
    CompletedHours: "Үткәрелгән ак. сәг.",
    LeftHours: "Калган ак. сәг.",
    Compensation: "Компенсация",
    Students: "Укучылар",
    Duration: "Дәвамлылык",
  },
  GroupFilter: {
    ActiveGroups: "Актив төркемнәр",
    ActiveGroupsNo: "Юк",
    ActiveGroupsYes: "Әйе",
    SelectLevel: "Дәрәҗә сайлау",
    SearchStudent: "Укучыны эзләү",
    SearchGroup: "Төркемне эзләү",
    SelectModule: "Модуль сайлау",
    AllModules: "Барлык модульләр",
    AllCompanies: "Барлык компанияләр",
    SelectCompany: "Компания сайлау",
    StartDate: "Башлану көне",
    EndDate: "Тәмамлану көне",
    WithLessonsWithoutTeachers: "Укытучысыз",
    WithLessonsOnlyTechnicalCancellationBefore: "ДС. отменалар",
  },
  GroupDownload: {
    Download: "Йөкләү",
    Groups: "Группалар",
    WithoutPrice: "Бәясе юк төркемнәр",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Төркем исемен кертегез",
    SelectGroup: "Төркем сайлау",
  },
  GroupsEmptyTable: {
    FirstTextDefault: "Сезгә укыту модульләре билгеләнмәгән.",
    SecondTextDefault: "Уку координаторыгызга мөрәҗәгать итегез.",
    FirstTextDistributor: "Күрсәтү өчен мәгълүмат юк.",
    SecondTextDistributor: "Нинди дә булса мәгълүмат өстәп карагыз.",
    FirstTextClasses: "Күрсәтү өчен мәгълүмат юк.",
    SecondTextClasses:
      "Фильтрларны үзгәртеп карагыз яки ярдәмгә мөрәҗәгать итегез.",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "Укытучылар табылмады",
    SelectTeacher: "Укытучы сайлау",
  },
  ShortWeekDay: {
    "0": "ЙК",
    "1": "ДШ",
    "2": "СШ",
    "3": "ЧР",
    "4": "ПҖ",
    "5": "ҖМ",
    "6": "ШМ",
  },
  LessonStatuses: {
    completed: "Үткәрелгән",
    planned: "Запланировано",
    earlyCanceledByCustomer: "Клиент тарафыннан алдан отмена",
    earlyCanceledBySchool: "Мәктәп тарафыннан алдан отмена",
    lateCanceledByCustomer: "Клиент тарафыннан соңгы отмена",
    lateCanceledBySchool: "Мәктәп тарафыннан соңгы отмена",
    notPerformedToPay: "Түләү өчен",
  },
};

export default tt;
