import { useEffect, useMemo, useState } from "react";

import { Box, ThemeProvider, CssBaseline } from "@mui/material";
import { chunk } from "lodash";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { I18nextProvider } from "react-i18next";

import { defaultTheme } from "@packages/shared/themes/default";
import { useCancelableFetch } from "@packages/store/hooks";
import { useStores } from "@packages/store/models";
import { GetLearningGroupsParams } from "@packages/store/services/Api";

import { AttendanceReportGroups } from "./AttendanceReportGroups";
import {
  ReportsAttendanceFilter,
  ReportsAttendanceDownload,
} from "./components";
import { Loading } from "./components/Loading";
import { Title } from "./components/Title";
import { getDefaultValues } from "./helpers/getDefaultValues";
import i18n from "./locales/i18n";
import { WrapperMainStudents } from "./styledComponents";

const groupsPerPage = 30;

export interface Filters {
  startDate: Date | null;
  endDate: Date | null;
  groupId: string;
  studentId: string;
  companyId: string;
  onlyTeachersLessons: boolean; // Переключалка того чтобы преподавателю показывать только уроки преподавателя
}

interface AttendanceReportsProps {
  searchParams: URLSearchParams;
  syncSearchParams: (data: Filters) => void;
}

export const AttendanceReports = observer(
  (props: AttendanceReportsProps): JSX.Element => {
    const { searchParams, syncSearchParams } = props;

    const { loading, auth, customerCompany, learningGroup, api } = useStores();

    const { user } = auth;
    const { items: customerCompanies } = customerCompany;
    const { items: learningGroups } = learningGroup;

    const methods = useForm<Filters>({
      mode: "onChange",
      defaultValues: getDefaultValues(searchParams),
    });
    const { watch } = methods;
    const data = watch();
    const {
      startDate,
      endDate,
      groupId,
      studentId,
      companyId,
      onlyTeachersLessons,
    } = data;

    const dateRange = useMemo(
      () => ({ startDate, endDate }),
      [endDate, startDate]
    );

    const {
      fetch: fetchGroups,
      loading: loadingGroups,
      error: groupsError,
    } = useCancelableFetch();

    const [page, setPage] = useState(1);
    const [maximumGroups, setMaximumGroups] = useState(groupsPerPage);

    const [cachedCompanies, setCachedCompanies] = useState<string[]>([]);

    const {
      isHR = false,
      isStudent = false,
      isTeacher = false,
      isDistributor = false,
      isCoordinator = false,
    } = user ?? {};

    const downloadButtonVisible = Boolean(isHR ? true : groupId || companyId);
    const studentFilterVisible = !isDistributor && !isCoordinator && !isTeacher;

    const filteredGroups = computed(() => {
      return learningGroups
        .filter((item) => {
          if (isStudent) return true;

          return groupId ? item.id === groupId : true;
        })
        .filter((item) => {
          if (isStudent || !companyId) return true;

          return item.customerCompany?.id === companyId;
        })
        .filter(() => {
          if (isHR || isStudent) return true;

          return groupId || companyId;
        })
        .filter((item) => {
          if (!isStudent) return true;

          return item.status === "learning";
        })
        .sort((prev, next) => {
          const prevName = prev.name ?? "";
          const nextName = next.name ?? "";
          return nextName < prevName ? 1 : -1;
        });
    }).get();

    const groupsOnPage = computed(
      () => chunk(filteredGroups, groupsPerPage)[page - 1] ?? []
    ).get();

    useEffect(() => {
      if (!companyId) return;
      if (!cachedCompanies.includes(companyId)) {
        setCachedCompanies((prev) => [...prev, companyId]);
      }
    }, [cachedCompanies, companyId, customerCompanies, setCachedCompanies]);

    // Запрос групп на изменения фильтров
    useEffect(() => {
      const filters: GetLearningGroupsParams = {
        includeLessons: true,
      };
      if (companyId) {
        filters.company = companyId;
      }
      if (groupId) {
        filters.ids = [groupId];
      }
      if (page) {
        filters.page = page;
        filters.limit = groupsPerPage;
      }
      filters.sortBy = "name";
      // if (!filters.company && !filters.ids) return;
      fetchGroups((token) => api.getLearningGroups(filters, token));
    }, [companyId, groupId, learningGroups.length, page, fetchGroups, api]);

    // Отдельный запрос групп для HR
    useEffect(() => {
      if (!isHR) {
        return;
      }

      const filters: GetLearningGroupsParams = {
        includeLessons: true,
        page,
        sortBy: "name",
        limit: groupsPerPage,
      };

      fetchGroups((token) => api.getLearningGroups(filters, token));
    }, [isHR]);

    useEffect(() => {
      setPage(1);
    }, [groupId, studentId, companyId]);

    useEffect(() => {
      if (filteredGroups.length % groupsPerPage !== 0) {
        setMaximumGroups(filteredGroups.length);
        return;
      }
      setMaximumGroups(filteredGroups.length + groupsPerPage);
    }, [filteredGroups, filteredGroups.length]);

    // Синхронизация с url параметрами
    useEffect(() => {
      syncSearchParams(data);
    }, [data, syncSearchParams]);

    return (
      <ThemeProvider theme={defaultTheme}>
        <I18nextProvider i18n={i18n}>
          <WrapperMainStudents>
            <Box
              my="1.5rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Title />
              {downloadButtonVisible && (
                <ReportsAttendanceDownload
                  groups={filteredGroups}
                  dateRange={dateRange}
                  onlyTeachersLessons={onlyTeachersLessons}
                />
              )}
              {!downloadButtonVisible && <Box height="3rem" />}
            </Box>
            <Loading loading={loading} error={groupsError}>
              <>
                {/* Студенту показываются только свои группы */}
                {!isStudent && (
                  <ReportsAttendanceFilter
                    methods={methods}
                    additionalFilterVisible={downloadButtonVisible}
                    onlyTeachersLessonsFilterVisible={isTeacher}
                    studentFilterVisible={studentFilterVisible}
                    companyFilterVisible={!isHR}
                  />
                )}

                <AttendanceReportGroups
                  page={page}
                  groups={groupsOnPage}
                  groupsPerPage={groupsPerPage}
                  loading={loadingGroups}
                  student={studentId}
                  dateRange={dateRange}
                  maximumGroups={maximumGroups}
                  onPageChange={setPage}
                  onlyTeachersLessons={onlyTeachersLessons}
                />
              </>
            </Loading>
          </WrapperMainStudents>
        </I18nextProvider>

        <CssBaseline />
      </ThemeProvider>
    );
  }
);
