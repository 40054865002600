const tt = {
  Exercise: {
    Units: "Бүлекләр",
    Blocks: "Блоклар",
    Exercises: "Күнегүләр",
    Unit: "Бүлек",
    Block: "Блок",
    Exercise: "Күнегү",
    Script: "Видео сценарие",
    HideScript: "Сценарийне яшерү",
    ShowScript: "Сценарийне күрсәтү",
    LanguageSamples: "Тел үрнәкләре",
    Extras: "Өстәмә материаллар",
    ToolReload: "Яңарту",
    ToolTranslate: "Тәрҗемә итү",
    ToolCheck: "Тикшерү",
    ToolNext: "Алга",
    IsCheckedWarning: "Бирем инде тикшерелгән",
    NotFilledWarning: "Барлык сорауларга җавап бирелмәгән",
    Translate: "Тәрҗемә",
    Vocabulary: "Лексика",
    NoTranslation: "Тәрҗемә юк",
    AddToDictionary: "Сүзлеккә өстәү",
    SelectedTranslationsWarning: "1-3 тәрҗемәне сайлау кирәк",
    TranslateWordFailed: "Сүзне тәрҗемә итү булмады",
    AddWordsFailed: "Сүзләрне өстәү булмады",
    AddWordsSuccess: "Сүзләр уңышлы өстәлде",
    AddedBefore: "Элек өстәлгән",
    EnterText: "Текст кертегез",
    Characters: "Символлар",
    Words: "Сүзләр",
    TextSize: "Текст күләме:",
    WordsAtMinimum: "Минимум сүзләр",
    LearnWords: "Сүзләрне өйрәнү",
    Ok: "Ок",
    Expand: "Киңәйтү",
    Collapse: "Кысу",
    Close: "Ябу",
    ResizeTooltip: "Уку өчен киңәйтү",
    ResetChangesTooltip: "Барлык үзгәрешләрне кире кайтару",
    NoChange: "Үзгәрешләрсез",
    CorrectAnswer: "Дөрес җавап",
    DevTooltip: "Бу күнегү эшләнә, иске интерфейска күчү өчен",
    FollowLink: "Сылтама буенча күчү",
    CreativeExercisePassed: "Күнегү тикшерүгә җибәрелде",
    CreativeExerciseVerified: "Эшегез тикшерелгән. Басыгыз",
    CreativeExerciseVerifiedLink: "монда",
    DialogExerciseConfirmAnswer: "Раслау",
    DialogExerciseResetAnswer: "Кире язу",
    DialogExerciseSelectAnswer: "Бу",
    DialogExerciseHelperLegend: "Күнегүне ничек үтәү",
    DialogExerciseFinishHelperLegend: "Котлыйбыз!",
    DialogExerciseFirstHelper:
      "Мөгаен җавапны сайлап, микрофонга басып әйтегез.",
    DialogExerciseSecondHelper:
      "Система тавышыгызны таныды! Я җавапны раслатыгыз, я яңадан әйтегез.",
    DialogExerciseThirdHelper:
      "Тавышка җавап бирү мөмкинлеге бетте, кулдан җавапны сайлагыз.",
    DialogExerciseFinishHelper:
      "Күнегү үтәлде. Барлык диалогны тыңлау яки Тикшерүгә басып дәвам итү.",
    DialogExerciseRecognitionError:
      "Система җавапны танымады, яңадан тырышыгыз.",
    AudioRecorderText: "Язу өчен басыгыз ({{count}} сек)",
    RadioLineHelperTextLeft: "Барлык дөрес җаваплар бирелмәгән. Тагын табыгыз",
    RadioLineHelperTextRight: "дөрес җаваплар.",
    NextDisabledWarning: "Күнегүне тикшерү",
    BrokenError: "Бу күнегү эшләнә, иске интерфейска күчү өчен.",
    BrokenErrorLink: "Сылтама",
  },
  ResultsModals: {
    UnitTitle: "Бүлек нәтиҗәләре {{name}}",
    BlockTitle: "Блок нәтиҗәләре {{name}}",
    Passed: "Дөрес: {{count}}",
    Wrong: "Хаталар: {{count}}",
    Skipped: "Калдырылган: {{count}}",
    Blocks: "Блоклар",
    Block: "Блок {{order}}: {{name}}",
    Exercises: "Күнегүләр",
    Exercise: "Күнегү {{order}}: {{name}}",
    Repeat: "Кабатлау",
    Continue: "Дәвам итү",
    DontShowAgain:
      "Бу тәрәзне башка күрсәтмәү (профиль көйләүләрендә яңадан кушарга була)",
    TestUnitTitle: "Котлыйбыз! Сез {{order}} тест этабын тәмамладыгыз",
    TestUnitResultTitle: "Нәтиҗәгез -",
    TestUnit: "Бүлек {{order}}. {{name}}",
    TestUnitTotalProgress: "Гомуми прогресс",
    TestUnitResults: "Нәтиҗәләр",
    TestUnitNext: "Киләсе этапка башлау",
    TestUnitScoredPoints_zero: "{{count}}/{{total}} баллар",
    TestUnitScoredPoints_one: "{{count}}/{{total}} балл",
    TestUnitScoredPoints_few: "{{count}}/{{total}} балл",
    TestUnitScoredPoints_many: "{{count}}/{{total}} балллар",
    TestCourseTitle: "Котлыйбыз! Сез тестны тәмамладыгыз",
    TestCourseRecomendedLevel:
      "Сезгә түбәндәге дәрәҗәдәге программа тәкъдим ителә -",
    TestCourseTotalProgress: "Гомуми прогресс",
    TestCourseResultProgress: "Нәтиҗәләр",
    TestCourseShowResults: "Нәтиҗәләрне карау",
    TestCourseShowHourse: "Ак. сәг",
    TestCourseHint:
      "Тел күнекмәләре үстерүгә басым ясала (сөйләм, тыңлау, уку һәм язу)",
    TestCourseA1Long:
      "Әңгәмәнең төп мәгънәсен аңлыйм. Дусларым һәм гаиләм турында сөйли алам",
    TestCourseA2Long:
      "Аерым җөмләләрне аңлыйм һәм көндәлек темаларда сөйләшә алам",
    TestCourseB1Long: "Барлык вакытларны кулланып, күбесенчә аралаша алам",
    TestCourseB2Long: "Үз фикеремне яклый алам, сөйләшә һәм хат алыша алам",
    TestCourseC1Long: "Әдәби әсәрләрне укыйм һәм презентацияләр ясыйм",
    TestCourseC2Long: "Носитель дәрәҗәсенә якын телдә аралашам",
    TestCourseA1Short: "башлангыч",
    TestCourseA2Short: "башлангыч",
    TestCourseB1Short: "уртача",
    TestCourseB2Short: "уртадан югарырак",
    TestCourseC1Short: "җитлеккән",
    TestCourseC2Short: "камил",
  },
  TestingCourseResult: {
    ModalTitle: "Тест нәтиҗәләре",
    Confirm: "Нәтиҗәләргә күчү",
    ModalBodyEntrance:
      "Хөрмәтле {{fullName}}, Сезгә CEFR шкаласы буенча {{cefrLevel}} дәрәҗәсендә курс тәкъдим ителә.",
    ModalBodyFinal:
      'Хөрмәтле {{fullName}}, Сез тестны тәмамладыгыз, $t(TestingCourseResult:courseMark, {"count": {{courseMark}} }) алган һәм {{maxMark}}.\nНәтиҗәгез - {{percentage}}%.',
    ModalBodyDefault: "Хөрмәтле {{fullName}}, Сез тестны уңышлы тәмамладыгыз.",
    courseMark_zero: "{{count}} баллар",
    courseMark_one: "{{count}} балл",
    courseMark_few: "{{count}} балллар",
    courseMark_many: "{{count}} балллар",
  },
};

export default tt;
