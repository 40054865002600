const tt = {
  Classes: {
    SampleByStatus: "Период буенча дәрес статусларын сайлау",
    OnlyLessonsWithoutGroup: "Фәкать төркемсез дәресләр",
    Title: "Дәресләр",
    Company: "Компания",
    Group: "Төркем",
    Status: "Статус",
    ClassIndex: "Дәрес",
    ClassDate: "Дәрес көне",
    Teacher: "Укытучы",
    None: "Берни дә сайланмаган",
    Process: "Эшкәртү",
    DateRangeError: "Эшкәртү өчен башлану һәм тәмамлану көннәрен сайлагыз.",
    NoDataError: "Эшкәртү өчен мәгълүмат юк.",
    StandardHelper:
      "Мәгълүматны күрсәтү өчен, ким дигәндә бер фильтр кулланыгыз.",
    ProcessHelper: "Мәгълүматны күрсәтү өчен көнне сайлагыз.",
    Processing: "Мәгълүмат эшкәртелә",
    Type: "Тип",
    Unit: "Бүлек",
    Duration: "Ак.ч",
  },
  ClassesDownload: {
    Download: "Йөкләү",
    Classes: "Дәресләр",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Төркем исемен языгыз",
    SelectGroup: "Төркем сайлау",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "Укытучылар табылмады",
    SelectTeacher: "Укытучы сайлау",
  },
  Loading: {
    Error: "Йөкләү хатасы, битне яңартып карагыз",
  },
  DateRangePicker: {
    StartDate: "Башлану көне",
    EndDate: "Тәмамлану көне",
  },
  DatePicker: {
    Today: "Бүген",
    Clear: "Чистарту",
  },
  LessonStatuses: {
    planned: "Планлаштырылган",
    completed: "Үткәрелде",
    studentLateCancellation: "Тыңлаучылар тарафыннан соң өзелде",
    earlyCanceledByCustomer: "Тыңлаучылар тарафыннан алдан өзелде",
    earlyCanceledBySchool: "ДС Алдан өзелде",
    lateCanceledByCustomer: "Тыңлаучылар тарафыннан соң өзелде",
    lateCanceledBySchool: "ДС Соң өзелде",
    notPerformedToPay: "Түләү өчен",
  },
};

export default tt;
